export const Bell = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Icon">
      <mask id="mask0_2960_1002" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect id="Bounding box" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2960_1002)">
        <g id="Vector">
          <path fillRule="evenodd" clipRule="evenodd" d="M6.78791 3.6115C7.71375 2.72099 8.96944 2.2207 10.2788 2.2207C11.5881 2.2207 12.8438 2.72099 13.7696 3.6115C14.6955 4.50201 15.2156 5.70981 15.2156 6.96918C15.2156 9.28657 15.731 10.7309 16.2102 11.5759C16.4507 12 16.6856 12.2794 16.8502 12.4466C16.9327 12.5303 16.998 12.5863 17.0381 12.6185C17.0582 12.6346 17.072 12.6447 17.0785 12.6494L17.0818 12.6517C17.3133 12.8041 17.4159 13.0835 17.3344 13.3423C17.2522 13.6036 17.0019 13.7822 16.7181 13.7822H3.83942C3.55566 13.7822 3.30534 13.6036 3.22311 13.3423C3.14107 13.0817 3.24566 12.8002 3.48063 12.6485L3.477 12.6509L3.47477 12.6524L3.48063 12.6485C3.48716 12.6439 3.49932 12.6346 3.5194 12.6185C3.55954 12.5863 3.62484 12.5303 3.70733 12.4466C3.87197 12.2794 4.10686 12 4.34734 11.5759C4.82653 10.7309 5.34195 9.28657 5.34195 6.96918C5.34195 5.70981 5.86207 4.50201 6.78791 3.6115Z" fill="#1F1F1F" />
          <path fillRule="evenodd" clipRule="evenodd" d="M8.71786 15.3798C9.02548 15.2082 9.41954 15.3089 9.59799 15.6048C9.66717 15.7195 9.76645 15.8147 9.88593 15.8809C10.0054 15.9471 10.1409 15.982 10.2788 15.982C10.4166 15.982 10.5521 15.9471 10.6716 15.8809C10.7911 15.8147 10.8904 15.7195 10.9596 15.6048C11.138 15.3089 11.532 15.2082 11.8396 15.3798C12.1473 15.5515 12.252 15.9305 12.0735 16.2264C11.8912 16.5288 11.6294 16.7798 11.3144 16.9543C10.9994 17.1289 10.6423 17.2207 10.2788 17.2207C9.91527 17.2207 9.55813 17.1289 9.24314 16.9543C8.92816 16.7798 8.66638 16.5288 8.48399 16.2264C8.30554 15.9305 8.41023 15.5515 8.71786 15.3798Z" fill="#1F1F1F" />
        </g>
      </g>
    </g>
  </svg>
);
