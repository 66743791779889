import { useState } from 'react';
import { Form, useNavigation } from '@remix-run/react';
import { isValidUUID } from '../lib/utils/client-utils';

export default function SearchForm ({ error }) {
  const [inputCriteria, setInputCriteria] = useState({ valid: false, message: undefined });
  const transition = useNavigation();

  const onInputHandler = (e) => {
    const validUuid = isValidUUID(e.target.value);
    setInputCriteria({
      valid: validUuid,
      message: (!validUuid && e.target.value.length > 0) && 'Ogiltligt värde, ange ett korrekt ID'
    });
  };

  return (
    <div className="flex flex-col justify-center w-full lg:w-[36.25rem]">
    <Form id="search-article-form" method="POST">
      <label className="pb-1.5 font-semibold flex text-app-black" htmlFor="article_id">
        Lägg till Abbe-ID<span className='asterisk'>*</span>
      </label>
        <div className="mb-2">
          <div>
            <input
              onInput={onInputHandler}
              className="md:col-span-2 peer input-search w-full"
              placeholder="Ex. ccaecc4d-f259-4624-b27b-7bf98b800793"
              aria-label="Sök"
              aria-describedby="search-submit"
              name="q"
              id="article_id"
              type="search"
            />
            {inputCriteria.message && (
              <span className='w-full block text-app-red font-bold italic text-sm mt-2'>{inputCriteria.message}</span>
            )}
            <button
              className={`btn w-full mt-3.5 ${inputCriteria.valid ? 'btn-active' : 'btn-disabled'}`}
              id="search-submit"
              aria-label="Sök"
              type="submit"
              name="form-name"
              value="search-article"
              disabled={!inputCriteria.valid}
            >
              {transition.state === 'submitting' || transition.state === 'loading' ? 'Vänta...' : 'Nästa' }
            </button>
          </div>
      </div>
    </Form>
    {error && (<div className="max-w">{error}</div>)}
    </div>
  );
}
