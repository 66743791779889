import PushHistory from '~/components/push-history';
import PaperSelector from '~/components/paper-selector';

export default function PushOverview () {
  return (
    <section className="push-history bg-gray-100 w-full h-full">
      <div className="mx-auto w-full lg:w-5/6 pt-24 md:flex justify-between">
        <h1 className="text-2xl font-extrabold">Historik pushnotiser (manuella pushar)</h1>
        <PaperSelector />
      </div>
      <PushHistory />
    </section>
  );
}
