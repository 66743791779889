import SearchForm from '../components/search-form';
import SearchLinkForm from '~/components/search-link-form';
import { useEffect, useState } from 'react';
import {
  useLoaderData,
  useRouteError,
  isRouteErrorResponse,
  useRevalidator,
  useSearchParams,
} from '@remix-run/react';
import { isValidUUID, isValidUrl } from '~/lib/utils/validation.server';
import { getSubject, getLink } from '../lib/data/form-data.server';
import { redirect, json } from '@remix-run/node';
import Error from '~/components/errorHandling/error';
import db from '~/lib/data/db/db.server';
import Accordion from '~/components/accordion';
import { Bell } from '~/components/icons/bell';
import { History } from '~/components/icons/history';

import PushOverview from '~/components/push-overview';
import { Logo } from '~/components/icons/logo';
import { Flerp } from '~/components/icons/flerp';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import Exit from '~/components/icons/exit';

const UNDELIVERED_CHECK_INTERVAL = 2000;

export default function Index ({ error, initialType = 'search-link' }) {
  // eslint-disable-next-line no-template-curly-in-string, no-script-url
  const bookmarkJS = "javascript:(function(){const pushAppLink='https://push-app.bonniernews.se'; const hostname=window.location.hostname;if(hostname==='prod.abbe.bonniernews.se'){window.open(`${pushAppLink}/notification/new/${window.location.href.split('/').pop()}`)}else{const uuid=(document.querySelector('article')).getAttribute('data-uuid');window.open(`${pushAppLink}/notification/new/${uuid}`)}}())";
  const bookmarkLink = `<a href="${bookmarkJS}"><strong>Dra mig till bokmärkesfältet 🔖</strong></a>`;
  const [searchParams, setSearchParams] = useSearchParams();
  const menuItem = searchParams.get('menuItem') || initialType;

  const revalidator = useRevalidator();
  const data = useLoaderData();
  const { pushHistory = {}, userName } = data || {};
  const { items = [] } = pushHistory;
  const undeliveredNotifications = items.filter(notification => notification.state !== 'delivered');
  useEffect(() => {
    if (undeliveredNotifications.length === 0) {
      return;
    }
    const intervalId = setInterval(() => {
      revalidator.revalidate();
    }, UNDELIVERED_CHECK_INTERVAL);
    return () => clearInterval(intervalId);
  }, [undeliveredNotifications, revalidator]);

  const [activeType, setType] = useState(menuItem);
  const [hideMenu, setHideMenu] = useState(true);

  const isArticle = activeType === 'search-article';
  const isLink = activeType === 'search-link';
  const showHistory = activeType === 'show-history';
  const updateMenu = (type) => {
    setSearchParams((prev) => {
      prev.set('menuItem', type);
      return prev;
    });
    setHideMenu(true);
    setType(type);
  };

  return (
    <>
      <header className={`bg-app-black flex py-4 justify-between px-5 ${!hideMenu ? 'hidden lg:flex' : ''}`}>
        <div className='flex'>
          <button className='mr-4 lg:mr-0' onClick={() => setHideMenu(val => !val)}><Flerp className='block lg:hidden' /></button>
          <a className='mt-1' href="/">
            <Logo />
          </a>
        </div>
        <p className="text-white">{userName}</p>
      </header>
      <div className="bg-[#eef4ff] w-full flex flex-row h-full">
        <div className={`w-full h-full z-10 absolute lg:relative lg:w-[17rem] flex lg:justify-center bg-[#FBF8FF] border-[#E0C8FF] border-r-2 ${hideMenu ? 'hidden lg:flex' : ''}`}>
          <Exit className={`absolute top-4 left-4 lg:hidden ${hideMenu ? 'hidden' : ''}`} onClick={() => setHideMenu(true)} />
          <ul className='mt-8 w-full p-4'>
            <li className={`flex flex-row w-full lg:w-[15rem] py-4 rounded-sm ${activeType === 'search-link' && 'bg-[#F5EDFF]'} `}><Bell className='ml-4 mr-2' /><button className='font-medium' onClick={() => updateMenu('search-link')}>Ny pushnotis (URL)</button><ChevronRightIcon className='block stroke-2 bold ml-auto mr-4 lg:hidden w-4 h-4 mt-1' /></li>
            <li className={`flex flex-row w-full lg:w-[15rem] py-4 rounded-sm ${activeType === 'search-article' && 'bg-[#F5EDFF]'} `}><Bell className='ml-4 mr-2' /><button className='font-medium' onClick={() => updateMenu('search-article')}>Ny pushnotis (Abbe-ID)</button><ChevronRightIcon className='block stroke-2 bold ml-auto mr-4 lg:hidden w-4 h-4 mt-1' /></li>
            <li className={`flex flex-row w-full lg:w-[15rem] py-4 rounded-sm ${activeType === 'show-history' && 'bg-[#F5EDFF]'} `}><History className='ml-4 mr-2' /><button className='font-medium' onClick={() => updateMenu('show-history')}>Historik</button><ChevronRightIcon className='block stroke-2 bold ml-auto mr-4 lg:hidden w-4 h-4 mt-1' /></li>
          </ul>
        </div>
        <div className={`push-article-search flex items-center flex-col mx-auto pt-16 w-[90%] max-w-[550px] lg:min-h-[550px] ${isArticle ? '' : 'hidden'}`}>
          <h1 className="text-[2rem] font-extrabold mb-4 self-start">Ny pushnotis från Abbe-ID</h1>
          <div className="w-full">
              <div className="w-full mb-5">
                <SearchForm error={error?.props.page === 'search-article' && error}></SearchForm>
                <Accordion className="pt-12" title="Hur hämtar jag Abbe-ID?">
                  <ol className="pl-5 text-sm list-decimal">
                    <li>Klicka in på en artikel i Abbe</li>
                    <li>Kopiera numret i URLn. Exempel: <strong>”9282b1f9-82d5-480f-bcd6-afe9c3e9ed77”</strong></li>
                    <li>Klistra in i fältet ovan.</li>
                  </ol>
                </Accordion>
                <p className="list-item text-sm text-app-gray-text ml-4">
                  <strong>Bokmärke</strong> för att skapa notis direkt från artikel via sajt eller abbe <br/> 👉 <span className="underline text-app-purple" dangerouslySetInnerHTML={{ __html: bookmarkLink }}></span>
                </p>
                <p className="list-item text-sm text-app-gray-text ml-4">
                  Besök artikel i fråga på sajt och klicka på bokmärket. Alternativt leta upp artikeln i abbe och klicka på bokmärket.
                </p>
              </div>
          </div>
        </div>
        <div className={`push-link-input flex items-center flex-col mx-auto pt-16 w-[90%] max-w-[550px] lg:min-h-[550px] ${isLink ? '' : 'hidden'}`}>
          <h1 className="text-[2rem] font-extrabold mb-4 self-start">Länk-push</h1>
          <div className="w-full">
              <div className="w-full mb-5">
                <SearchLinkForm error={error?.props.page === 'search-link' && error}></SearchLinkForm>
              </div>
          </div>
        </div>
        <div className={`flex items-center flex-col w-full ${!hideMenu && 'overflow-hidden'} ${showHistory ? '' : 'hidden'}`}>
          <PushOverview />
        </div>
      </div>
    </>
  );
}

export function ErrorBoundary () {
  const caught = useRouteError();

  if (isRouteErrorResponse(caught)) {
    return (
    <Index
      error={ <Error
        page={caught?.data?.type}
        role="alert"
        title="Error"
        message={caught?.data.message}
        status={caught?.status}
        classes="peer-focus:hidden"
      />}
      initialType={caught?.data?.type}
    />
    );
  }

  return (
    <div>
      <h1>Uh oh ...</h1>
      <p>Something went wrong.</p>
      <pre>Unknown Error</pre>
    </div>
  );
}

export async function loader ({ request, context }) {
  const url = new URL(request.url);
  const activePublishingGroups = context?.activePublishingGroups || [];
  const search = new URLSearchParams(url.search);
  const appIds = search.get('appIds')?.split(',');
  const page = search.get('page') || 1;
  if (appIds) {
    const pushHistory = await db.pushNotifications.get(appIds, { page });
    return {
      appIds,
      pushHistory,
      activePublishingGroups,
      userName: context?.userName || 'User',
    };
  }

  return { activePublishingGroups, userName: context?.userName };
}

export async function action ({ request }) {
  const formData = await request.formData();

  const name = formData.get('form-name');
  let message;
  const q = formData.get('q');
  if (!q) return null;

  try {
    switch (name) {
      case 'search-article': {
        if (isValidUUID(q)) {
          await getSubject('article', q);
          return redirect(`/notification/new/${q}`);
        }
        message = 'Invalid id, the id must be formated like: ccaecc4d-f259-4624-b27b-7bf98b800793';
        break;
      }
      case 'search-link': {
        if (isValidUrl(q)) {
          await getLink(q);
          return redirect(`/notification/new-link/${encodeURIComponent(q)}`);
        }
        message = 'Invalid url, the url must be formated like: https://www.sydsvenskan.se/livesport';
      }
    }
  } catch (err) {
    throw json({ message: err.message, type: name }, { status: err.cause });
  }

  if (message) {
    throw json(
      {
        message,
      },
      { status: 400 }
    );
  }
}
