export const History = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Icon">
      <mask id="mask0_2960_1016" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect id="Bounding box" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2960_1016)">
        <path id="history" d="M10 17.5C8.08333 17.5 6.41319 16.8646 4.98958 15.5938C3.56597 14.3229 2.75 12.7361 2.54167 10.8333H4.25C4.44444 12.2778 5.08681 13.4722 6.17708 14.4167C7.26736 15.3611 8.54167 15.8333 10 15.8333C11.625 15.8333 13.0035 15.2674 14.1354 14.1354C15.2674 13.0035 15.8333 11.625 15.8333 10C15.8333 8.375 15.2674 6.99653 14.1354 5.86458C13.0035 4.73264 11.625 4.16667 10 4.16667C9.04167 4.16667 8.14583 4.38889 7.3125 4.83333C6.47917 5.27778 5.77778 5.88889 5.20833 6.66667H7.5V8.33333H2.5V3.33333H4.16667V5.29167C4.875 4.40278 5.73958 3.71528 6.76042 3.22917C7.78125 2.74306 8.86111 2.5 10 2.5C11.0417 2.5 12.0174 2.69792 12.9271 3.09375C13.8368 3.48958 14.6285 4.02431 15.3021 4.69792C15.9757 5.37153 16.5104 6.16319 16.9062 7.07292C17.3021 7.98264 17.5 8.95833 17.5 10C17.5 11.0417 17.3021 12.0174 16.9062 12.9271C16.5104 13.8368 15.9757 14.6285 15.3021 15.3021C14.6285 15.9757 13.8368 16.5104 12.9271 16.9062C12.0174 17.3021 11.0417 17.5 10 17.5ZM12.3333 13.5L9.16667 10.3333V5.83333H10.8333V9.66667L13.5 12.3333L12.3333 13.5Z" fill="#505762" />
      </g>
    </g>
  </svg>
);
